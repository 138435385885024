
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.my-services {
  width: 100%;
  margin: 0 auto;

  &__wrap {
    display: grid;
    gap: $gap;
    grid-template-columns: calc(100% - #{$gap} - #{$right-column-width}) $right-column-width;
    grid-auto-rows: max-content;

    @include for-size(phone-landscape-down) {
      grid-template-columns: 100%;
      gap: calc(#{$gap} / 2);
    }
  }

  &__main {
    display: grid;
    gap: $gap;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    @include for-size(phone-landscape-down) {
      order: 2;
      gap: calc(#{$gap} / 2);
    }
  }

  &__side {
    display: grid;
    gap: $gap;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    @include for-size(phone-landscape-down) {
      order: 1;
      gap: calc(#{$gap} / 2);
    }
  }

  &__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__articles-cnt {
    @include for-size(phone-landscape-down) {
      row-gap: 20px;
      columns: 2;
      .article-item {
        page-break-inside: avoid;
        break-inside: avoid-column;
        display:table;
      }
    }

    @include for-size(phone-portrait-down) {
      columns: unset;
      row-gap: unset;
    }
  }

  &__favorites-cnt {
    .swiper-pagination {
      position: relative;
      bottom: unset;
      left: unset;
      margin-top: $base-gutter;
    }

    .swiper-pagination-bullet-active {
      background: $color-client-portal-logo;
    }
  }

  &__favorite {
    border-radius: 12px;
    height: auto;

    .favorite-service {
      display: block;
      height: 100%;

      &:hover {
        box-shadow: $base-shadow-m;
      }
    }
  }

  &__news {
    .tile-grid {
      grid-template-columns: 1fr 1fr;

      @include for-size(phone-portrait-down) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__news-cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: -8px;
  }

  &__article {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .news-block {
    &__list {
      @include for-size(phone-portrait-down) {
        display: none;
      }
    }

    &__slider {
      display: none;
      @include for-size(phone-portrait-down) {
        display: block;
        width: 100vw;
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
      }

      .swiper-slide {
        width: 90% !important;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;

    span {
      padding-right: 4px;
    }
  }
  &__popular {
    .swiper-container {
      padding-bottom: 10px;
    }
  }
}

.surveys {

}

