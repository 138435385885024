
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.modal-feedback {
  &__error {
    margin: 5px 0 0 0;
    color: $error-color;
    font-size: 13px;
  }
  &__title {
    font-weight: bold;
  }
  &__item {
    margin-bottom: 15px;
  }
}
